import { jsx as _jsx } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import useAppNavigation from '@src/shared/lib/hooks/useAppNavigation';
import Icon from '@shared/ui/icons/Icon';
import EmptyViewInteractive from '@components/empty-views/EmptyViewInteractive';
import { ReactComponent as PortfoliosEmptyIcon } from '@icons/custom/portfolios-empty.svg';
import { ReactComponent as ExchangeIcon } from '@icons/custom/exchanges-empty.svg';
import { ReactComponent as ArrowIcon } from '@icons/wolfkit-light/arrow-left-light.svg';
import { CreatePortfolioButton } from '@features/portfolio/portfolio-create';
import Button from '@shared/ui/buttons/Button';
const ArrowIconStyled = styled(ArrowIcon)(() => ({
    transform: 'rotate(180deg)',
}));
const PortfoliosEmptyView = ({ className = undefined, profileType, isMyProfile, haveExchanges = undefined, }) => {
    const { t } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const goToMarketplace = () => {
        navigate(routes.MARKETPLACE);
    };
    const goToOverview = () => {
        navigate(routes.HOME_OVERVIEW);
    };
    const { messageTitle, BodyImgIcon, messageBody, ActionBtn, } = useMemo(() => {
        if (typeof profileType !== 'undefined') {
            if (profileType === 'TRADER') {
                if (isMyProfile && !haveExchanges) {
                    return {
                        messageTitle: t('portfolio.empty.my_trader_no_exchanges.title'),
                        messageBody: t('portfolio.empty.my_trader_no_exchanges.body'),
                        BodyImgIcon: PortfoliosEmptyIcon,
                        ActionBtn: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', onClick: goToOverview, endIcon: ArrowIconStyled, children: t('navigation.go_to', {
                                location: t('pages.overview.overview', { ns: 'common' }),
                                ns: 'common',
                            }) })),
                    };
                }
                if (isMyProfile && haveExchanges) {
                    return {
                        messageTitle: t('portfolio.empty.my_trader_no_active_portfolios.title'),
                        messageBody: t('portfolio.empty.my_trader_no_active_portfolios.body'),
                        BodyImgIcon: PortfoliosEmptyIcon,
                        ActionBtn: (_jsx(CreatePortfolioButton, { variant: 'filled', color: 'primary', size: 'medium', endIcon: ArrowIconStyled, children: t('portfolio.empty.my_trader_no_active_portfolios.btn_text') })),
                    };
                }
                return {
                    messageTitle: t('portfolio.empty.trader.title'),
                    messageBody: t('portfolio.empty.trader.body'),
                    BodyImgIcon: PortfoliosEmptyIcon,
                    ActionBtn: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', onClick: goToMarketplace, endIcon: ArrowIconStyled, children: t('navigation.go_to', {
                            location: t('pages.marketplace.marketplace', { ns: 'common' }),
                            ns: 'common',
                        }) })),
                };
            }
            if (isMyProfile) {
                return {
                    messageTitle: t('portfolio.empty.client_my_profile.title'),
                    messageBody: t('portfolio.empty.client_my_profile.body'),
                    BodyImgIcon: ExchangeIcon,
                    ActionBtn: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', endIcon: ArrowIconStyled, onClick: goToOverview, children: t('navigation.go_to', {
                            location: t('pages.overview.overview', { ns: 'common' }),
                            ns: 'common',
                        }) })),
                };
            }
            return {
                messageTitle: t('portfolio.empty.client.title'),
                messageBody: t('portfolio.empty.client.body'),
                BodyImgIcon: PortfoliosEmptyIcon,
                ActionBtn: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'medium', endIcon: ArrowIconStyled, onClick: goToMarketplace, children: t('navigation.go_to', {
                        location: t('pages.marketplace.marketplace', { ns: 'common' }),
                        ns: 'common',
                    }) })),
            };
        }
        return {
            messageTitle: '',
            messageBody: '',
            BodyImgIcon: null,
            ActionBtn: null,
        };
    }, [profileType, isMyProfile, haveExchanges]);
    return (_jsx(EmptyViewInteractive, { className: className, messageTitle: messageTitle, messageBody: messageBody, ActionBtn: ActionBtn, BodyImgComponent: (BodyImgIcon !== null ? (_jsx(Icon, { size: 200, keepOriginalColor: true, IconComponent: BodyImgIcon })) : null) }));
};
export default PortfoliosEmptyView;
